import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  WeatherForecastProject,
  PhotograptherPortfolio,
  MovieSearchLink,
  Translator,
} from "./Projects/Projects";

export function Hobbies() {
  const hobbiesList = [
    "https://amantes30.github.io/icons/PNG/val-logo-small.png",
    "https://amantes30.github.io/icons/PNG/3dsmax.png",
    "https://amantes30.github.io/icons/PNG/blender.png",
    "https://amantes30.github.io/icons/PNG/twitch.png",
  ];
  const list = hobbiesList.map((iconUrl) => {
    let altName = iconUrl.split("/")[2].split(".")[0];
    return (
      <li key={uuidv4()}>
        <section style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <img style={{ height: "100%" }} src="/image/yosias G.png"></img>
          </div>
        </section>
        <section style={{}}>
          {" "}
          <a style={{ width: "100px" }} href="" key={uuidv4()}>
            <img
              style={{ maxWidth: "100px" }}
              className="skill-icons shadowed"
              alt={altName}
              src={iconUrl}
            ></img>
          </a>
        </section>
      </li>
    );
  });
  return (
    <div className="item hobbies-item">
      <ul className="hobbieslist">{list}</ul>
    </div>
  );
}

export function AboutMeItem({ username, profession, nationality }) {
  return (
    <div className="item aboutme">
      <div className="pfp" style={{ animationTimeline: "view(10% 90%)" }}>
        <img
          style={{ objectFit: "cover", pointerEvents: "none" }}
          width={"100%"}
          height={"100%"}
          alt="pfp"
          src="/image/pfp.png"
        ></img>
      </div>
      <h1 className="gradient-colored-text" id="aboutmetext">
        {username}
      </h1>
      <h2
        className="gradient-colored-text degree"
        onMouseEnter={() => {
          document.getElementById("degree-img").style.scale = "1";
        }}
        onMouseLeave={() => {
          document.getElementById("degree-img").style.scale = "0";
        }}
      >
        {">" + profession}
        <div id="degree-img">
          <img src="/image/degree.png" />
        </div>
      </h2>

      <h2 className="gradient-colored-text">{">_"}</h2>
    </div>
  );
}
export function ProjectsItem() {
  const [viewProject, setView] = useState(4);
  let [myitem, setmyitem] = useState("");
  useEffect(() => {
    SelectItem(viewProject);
  }, [viewProject]);

  function SelectItem(num) {
    switch (num) {
      case 1:
        setmyitem(<MovieSearchLink />);
        break;
      case 2:
        setmyitem(<MovieSearchLink />);
        break;
      case 3:
        setmyitem(<WeatherForecastProject />);
        break;
      case 4:
        setmyitem(<PhotograptherPortfolio />);
        break;
    }
    setView(parseInt(num));
  }

  return (
    <div className="item projects-item">
      <h1 className="title gradient-colored-text">Projects</h1>
      <hr />
      <ul className="project-list">
        <li>
          <PhotograptherPortfolio />
        </li>
        <li>
          <WeatherForecastProject />
        </li>
        <li>
          <MovieSearchLink />
        </li>
        <li>
          <Translator />
        </li>
        <li>
          <PhotograptherPortfolio />
        </li>
        <li>
          <WeatherForecastProject />
        </li>
        <li>
          <MovieSearchLink />
        </li>
        <li>
          <Translator />
        </li>
      </ul>
      {/* {myitem} */}
    </div>
  );
}
export function ExperienceItem({ experiences }) {
  var list = [];

  experiences.map((experience) => {
    return list.push(
      <li key={uuidv4()}>
        <img src="https://git.amantes30.com/PNG/vswork-logo2024.png" />
        <div>
          <a
            style={{ color: "white", textDecoration: "none" }}
            href={"https://" + experience.link}
          >
            <h3>{experience.organization}</h3>
          </a>

          <p>{experience["job"]}</p>
          {/* parse html from the text */}

          {experience.description}
        </div>
      </li>
    );
  });

  return (
    <div className="item experience-item">
      <h1 className="title gradient-colored-text">Internship</h1>
      <hr />
      <ul>{list}</ul>
    </div>
  );
}

export function SkillItem({ skills }) {
  var list = [];
  var list2 = [];
  var list3 = [];
  skills.forEach((skill) => {
    switch (skill.type) {
      case "lang":
        list.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icons shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      case "framework":
        list2.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icons shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      case "tool":
        list3.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icons shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      default:
        break;
    }
  });
  return (
    <>
      <div className="item skills">
        <div
          className="skills-animoji"
          style={{ animationTimeline: "view(70% 20%)" }}
        >
          <img src="/image/animoji.png"></img>
        </div>

        <section className="skill-section">
          <h2 className="gradient-colored-text">Programming languages</h2>
          <ul className="skillitemist scrollable">
            {list}
            {list}
          </ul>
        </section>

        <section className="skill-section">
          <h2 className="gradient-colored-text">Frameworks</h2>
          <ul className="skillitemist">{list2}</ul>
        </section>

        <section className="skill-section">
          <h2 className="gradient-colored-text">Tools</h2>
          <ul className="skillitemist scrollable">
            {list3}
            {list3}
          </ul>
        </section>

        {/* <p>3D modelling (3ds Max)</p> */}
      </div>
    </>
  );
}

export function ChatBox({ socket, setLoadStatus }) {
  return (
    <>
      <div className="chat closed">
        <div className="chat-header">
          <div className="chat-logo">
            <img src="https://git.amantes30.com/SVG/logo(new).svg"></img>
          </div>
          <button className="chat-close" onClick={toggleChat}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="bisque"
              className="bi bi-x-square"
              viewBox="0 0 16 16"
            >
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </button>
        </div>
        <div className="chat-body">
          <div className="chat-message">
            <div className="chat-message-content">
              <ul id="messages">
                <li className="msg"></li>
              </ul>
            </div>

            <form
              id="chat-form"
              style={{ display: "none" }}
              onSubmit={(e) => {
                e.preventDefault();
                if (!socket.connected) {
                  alert("Please input your email first");
                  return;
                }
                socket.emit("message", {
                  message: e.target.message.value,
                  sender: window.localStorage.getItem("email"),
                  timeStamp: new Date().toISOString(),
                  roomId: window.localStorage.getItem("roomID"),
                });
              }}
            >
              <input
                type="text"
                placeholder="say what?"
                className="form-control"
                name="message"
                autoComplete="off"
              />
              <button type="submit" id="send-btn">
                Send
              </button>
            </form>

            <form
              id="hidden-chat-form"
              onSubmit={async (e) => {
                e.preventDefault();

                const input = document.getElementById("email-input");
                input.classList.toggle("loading");
                input.readOnly = true;
                setLoadStatus(true);
                //verify email
                const isVerified = await axios
                  .get(
                    `${process.env.REACT_APP_SERVER_URL}/verify-email?email=${e.target.email.value}`
                  )
                  .then((res) => {
                    setLoadStatus(false);
                    return res.data;
                  });

                if (isVerified) {
                  window.localStorage.setItem("email", e.target.email.value);
                  //window.localStorage.setItem("roomID", _roomID);

                  if (socket.connected) {
                    socket.emit("setuser", {
                      email: e.target.email.value,
                      timeStamp: new Date().toISOString(),
                    });
                    // welcome message
                    const messages = document.getElementById("messages");
                    const li = document.createElement("li");
                    const p = document.createElement("p");
                    p.textContent = `Welcome to the chat. Please give me a few seconds to connect to the server.\n Feel free to put in your inquiry, I can read it when I'm online. Please do not refresh your page. Chat history is only available on my end`;
                    li.className = "msg";
                    li.appendChild(p);
                    messages.appendChild(li);
                  } else {
                    alert(
                      "Server is down, This feature is not available at the moment"
                    );
                  }

                  e.target.reset();
                  e.target.style = "display:none";
                  document.getElementById("chat-form").style = "display:flex";
                } else {
                  alert(
                    `The email ${e.target.email.value} could not be verified. Please make sure you have entered the correct email.`
                  );
                  input.classList.toggle("loading");
                  input.readOnly = false;

                  e.target.reset();
                }

                //socket.emit('setchat', e.target.email.value)
              }}
            >
              <input
                type="email"
                placeholder="Email"
                className="form-control"
                name="email"
                autoComplete="off"
                required
                id="email-input"
              />
              <button type="submit" id="send-btn">
                set
              </button>
            </form>
          </div>
        </div>
      </div>
      <button className="chatbox-toggle" onClick={toggleChat}>
        <p style={{ margin: 0 }}>Chat with me</p>
      </button>
    </>
  );
}

function toggleChat(e) {
  e.preventDefault();
  const chat = document.querySelector(".chat");
  const chatboxToggle = document.querySelector(".chatbox-toggle");
  const messages = document.getElementById("messages");
  const inputField = document.getElementById("email-input");
  if (chat.classList.contains("closed")) {
    chatboxToggle.style = "display:none";
    chat.classList.toggle("closed", false);
    messages.scrollTop = messages.scrollHeight;
    inputField.focus();
  } else {
    chat.classList.toggle("closed", true);
    chatboxToggle.style = "display:flex, text-align:center";
  }
}
