function Footer() {
  
  return (
    <footer>
      <a href="https://github.com/amantes30" style={{height: " 30px"}}>
        <img style={{ height: '100%', width: '30px'}} src=" https://amantes30.github.io/icons/SVG/github.svg"/>
      </a>
    </footer>
  );
}
export default Footer;
