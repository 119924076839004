export function MoviePlayer() {
  let movieUrl = "tt0133093";
  return (
    <>
      <div style={{ width: "90%", maxWidth: "500px", height: "300px" }}>
        <iframe
          style={{ width: "100%", height: "100%", borderRadius: "50px" }}
          allowFullScreen
          src={"https://vidsrc.to/embed/movie/" + movieUrl}
        ></iframe>
      </div>
      <p>
        This player is powered by a third party company. Please beware of the
        Ads
      </p>
    </>
  );
}

export function MovieSearchLink() {
  return (
    <PreviewCard
      description="This website uses TVMaze API to search for movies"
      link="https://amantes30.github.io/MovieSearch/"
      imageLink="/image/moviesearch.png"
    />
  );
}
export function WeatherForecastProject() {
  return (
    <PreviewCard
      description="This website uses 7Timer API to retrieve 7 day weather forecast for different cities"
      link="https://weather-forecast-eta-seven.vercel.app"
      imageLink="/image/weathersite.png"
    />
  );
}
export function Translator() {
  return (
    <PreviewCard
      description="Translator website that uses Google's API to translate text"
      link="https://my-repo-six-lyart.vercel.app"
      imageLink="/image/translator.png"
    />
  );
}
export function PreviewCard({ description, imageLink, link }) {
  return (
    <>
      <figure
        className="project-preview"
        onMouseOver={handleHover}
        onMouseOut={handleHover}
      >
        <div className="project-image">
          <img src={imageLink}></img>
        </div>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <figcaption>
            <p>{description}</p>
          </figcaption>
        </a>
      </figure>
    </>
  );
}
export function PhotograptherPortfolio() {
  return (
    <PreviewCard
      description="This website integrates Unsplash's API to retrieve client's pictures and showcase in the portfolio"
      link="https://yozz1.pages.dev"
      imageLink="/image/yosias G.png"
    />
  );
}

function handleHover(e) {
  if (e.type === "mouseover") {
    //console.log("Mouse is still on the element");
    if (e.target.classList.contains("on")) return;
    e.target.classList.add("on");
  } else if (e.type === "mouseout") {
    //console.log("Mouse is no longer on the element");
    if (!e.target.classList.contains("on")) return;
    e.target.classList.remove("on");
  }
}
