import { useEffect, useState } from "react";
import axios from "axios";
import { io } from "socket.io-client";

import ItemList from "./components/ItemList.js";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import User from "./models/user.ts";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./styles/style.css";
import "./styles/App.css";

function App() {
  const [user, setUser] = useState(new User());
  const [loading, setLoadStatus] = useState(true);
  const [socket, setSocket] = useState(null);
  const getData = async () => {
    const params = {
      isAdmin: true,
    };
    axios
      .get("/data.json")
      .then((res) => {
        setLoadStatus(false);
        setUser(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  useEffect(() => {
    initSocket();
    getData();
  }, []);

  function initSocket() {
    const socket = io(process.env.REACT_APP_SERVER_URL);
    socket.on("connect", (socket) => {
      console.log("Connected to server");
    });
    socket.on("message", (data) => {
      const chatForm = document.getElementById("chat-form");
      const messages = document.getElementById("messages");
      const li = document.createElement("li");
      const p = document.createElement("p");
      p.textContent = data.message;
      li.className = data.sender === "admin" ? "msg" : "msg i";
      li.appendChild(p);
      messages.appendChild(li);
      chatForm.reset();
      messages.scrollTop = messages.scrollHeight;
    });
    socket.on("setuser", (data) => {
      window.localStorage.setItem("email", data.email);
      window.localStorage.setItem("roomID", data.roomId);
    });
    socket.on("admin-joined-room", (roomId) => {
      const chatForm = document.getElementById("chat-form");
      const messages = document.getElementById("messages");
      const li = document.createElement("li");
      const p = document.createElement("p");
      p.textContent = `Aman has joined you`;

      li.appendChild(p);
      messages.appendChild(li);
      chatForm.reset();
      messages.scrollTop = messages.scrollHeight;
    });
    socket.on("disconnect", (socket) => {
      socket.emit("message", {
        message: "User has disconnected",
        sender: window.localStorage.getItem("email"),
        roomID: window.localStorage.getItem("roomID"),
      });
      console.log("Disconnected from server");
    });
    setSocket(socket);
  }
  return (
    <>
      {loading && <LoadingScreen />}
      <Header />
      {user.idinfo ? <ItemList user={user} socket={socket} setLoadStatus={setLoadStatus}/> : ""}
      <Footer />
    </>
  );
}

export default App;

function LoadingScreen() {
  const pStyle = {
    maxWidth: "350px",
    textAlign: "center",
  };
  return (
    <div className="load-wrapper">
      <div className="spinner-grow text-primary" role="status">
        <span className="visually-hidden">Loading</span>
      </div>
      <p style={pStyle}>
        The site is accessing data from <a href="https://render.com/">render</a>. Hence, it might take few
        seconds to start the server. Thanks for your patience
      </p>
    </div>
  );
}
