
import { AboutMeItem, ChatBox, ExperienceItem, ProjectsItem, SkillItem } from "./Item";
import "../styles/chat.css";

function ItemList({ user, socket, setLoadStatus }) {
  return (
    <>
      <main className="wrapper">
        <AboutMeItem
          username={user.idinfo[0].firstname}
          profession={user.idinfo[0].profession}
          nationality={user.idinfo[0].nationality}
        />
        <SkillItem skills={user.skills} />
        <ExperienceItem experiences={user.experiences} />
        <ProjectsItem projects={user.projects} />
        <ChatBox socket={socket} setLoadStatus={setLoadStatus} />
      </main>
    </>
  );
}
export default ItemList;


